import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validate from './validate';
import { strings } from '../../utils/localizedStrings';

let LoginForm = props => {
  const { handleSubmit, submitting, onSubmit, user } = props;
  const { lang } = user;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label className="text fs-12" htmlFor="username">
          {strings[lang].username}
          <Field
            name="username"
            id="username"
            component="input"
            className="ant-input"
          />
        </label>
        <div className="pre-icon os-icon os-icon-user-male-circle" />
      </div>
      <div className="form-group mrg-top-10">
        <label className="text fs-12" htmlFor="password">
          {strings[lang].psswrd}
          <Field
            name="password"
            id="password"
            component="input"
            type="password"
            className="ant-input"
          />
        </label>
        <div className="pre-icon os-icon os-icon-fingerprint" />
      </div>
      <div className="buttons-w">
        <button
          className="ant-button full-width"
          type="submit"
          disabled={submitting}
        >
          {strings[lang].logMeIn}
        </button>
      </div>
    </form>
  );
};

LoginForm = reduxForm({
  form: 'enterpriseLogin',
  validate
})(LoginForm);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  user: PropTypes.any
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(LoginForm);
