/* eslint-disable no-empty */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';
import Cookies from 'universal-cookie';
import { login } from '../service';
import toast from 'react-hot-toast';
import LoginForm from './form/login';
import { strings } from '../utils/localizedStrings'

const cookies = new Cookies();

class Login extends React.Component {
  handleSubmit = () => {
    const { username, password } = this.props.loginForm;
    return this.props.handleSubmit({ username, password }).then(res => {
      if (res.status === 200) {
        if(res.data && res.data.meta && res.data.meta.status >= 400){
          toast.error('Unable to log in with provided credentials');
        } else if('token' in res.data){
          this.props.history.push('/returns');
        }
      } else {
        toast.error('Unable to log in with provided credentials');
      }
    });
  };

  componentDidMount() {
    const token = cookies.get('token');
    if (token) {
      this.props.history.push('/returns');
    }
  }

  render() {
    const { lang } = this.props.user;
    return (
      <div className="auth-wrapper">
        <div className="all-wrapper menu-side with-pattern">
          <div className="auth-box-w">
            <p className="auth-header pd-top-40 fs-25 text-left">{strings[lang].login}</p>
            <LoginForm onSubmit={this.handleSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.object,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  loginForm: PropTypes.object,
  user: PropTypes.any
};

Login.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  login: state.login,
  loginForm: formValueSelector('enterpriseLogin')(state, 'username', 'password'),
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: query => dispatch(login(query))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
